import * as React from 'react';

// @mui
import { Container, Grid, Typography, useTheme } from "@mui/material";
// hooks
import useSettings from '../hooks/useSettings';
// components
import DashboardLayout from '../layouts/dashboard';

import Page from '../components/Page';

import { NetworkOverview } from "../sections";
import { usePolkawatchApi } from "../contexts/PolkawatchAPIConext";

// ----------------------------------------------------------------------

export default function NetworkPage() {
    const { themeStretch } = useSettings();
    const theme = useTheme();
    const { chainMeta } = usePolkawatchApi();

    return (
        <DashboardLayout>
            <Page title={chainMeta.HasStaking ? "Rewards by Network" : "Decentralization by Network" }>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <NetworkOverview/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
